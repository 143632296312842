import React from "react"
import { Helmet } from "react-helmet"

import NavBar from "./navbar"
import Footer from "./footer"
import "./layout.scss"

const Layout = ({ children }) => (
  <>
    <Helmet>
      <body className="has-navbar-fixed-top" />
      <title>Chromatin Biology Lab</title>
    </Helmet>
    <NavBar />
    {children}
    <Footer />
  </>
)

export default Layout
