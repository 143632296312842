import React from "react"

const Footer = () => (
  <footer className="footer">
    <div className="columns is-centered is-variable is-8">
      <p className="column is-one-third has-text-weight-medium">
        Josep Carreras Leukaemia Research Institute Can Ruti Campus, Ctra de Can
        Ruti, Camí de les Escoles s/n <br /> 08916 Badalona, Barcelona
      </p>
      <p className="column is-one-third has-text-weight-medium">
        Office (+34)-935572800 ext 4240 <br /> Lab (+34)-935572800 ext 4241
      </p>
    </div>
    <p className="mt-6 has-text-centered has-text-weight-bold">
      ©{new Date().getFullYear()} - Chromatin Biology Lab
    </p>
  </footer>
)

export default Footer
