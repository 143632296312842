import React, { useRef, useState, useEffect } from "react"
import { Link } from "gatsby"

import Logo from "../../static/chromatin-logo-indigo-dye.svg"

const NavBar = () => {
  const [isNavbarActive, setNavbar] = useState(false)
  const navbarBurger = useRef()
  const navbarMenu = useRef()

  const toggleMenu = () => {
    navbarBurger.current.classList.toggle("is-active")
    navbarMenu.current.classList.toggle("is-active")
  }

  const updateNavbarColorOnScroll = () => {
    if (window.scrollY >= 80) {
      setNavbar(true)
    } else {
      setNavbar(false)
    }
  }

  useEffect(() => {
    window.addEventListener("scroll", updateNavbarColorOnScroll)
    return () => window.removeEventListener("scroll", updateNavbarColorOnScroll)
  }, [])

  return (
    <header>
      <nav
        className={`navbar is-fixed-top ${isNavbarActive ? "is-active" : ""}`}
        role="navigation"
        aria-label="main navigation"
      >
        <div className="navbar-brand">
          <Link className="navbar-item" to="/">
            <img src={Logo} alt="Chromatin Biology Lab" height="300" />
            <span className="title is-4 ml-3 logo is-hidden-mobile primary-color">
              Chromatin biology lab
            </span>
          </Link>
          <button
            className="navbar-burger"
            aria-label="menu"
            aria-expanded="false"
            data-target="navbar-menu"
            ref={navbarBurger}
            onClick={toggleMenu}
          >
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
          </button>
        </div>
        <div className="navbar-menu" ref={navbarMenu}>
          <div className="navbar-end">
            <Link
              className="navbar-item has-text-weight-semibold"
              to="/research"
            >
              Research
            </Link>
            <div className="navbar-item has-dropdown is-hoverable">
              <span className="navbar-link is-arrowless has-text-weight-semibold">
                Members
              </span>
              <div className="navbar-dropdown">
                <Link className="navbar-item" to="/current-members">
                  Current members
                </Link>
                <Link className="navbar-item" to="/former-members">
                  Former members
                </Link>
              </div>
            </div>
            <Link
              className="navbar-item has-text-weight-semibold"
              to="/publications"
            >
              Publications
            </Link>
            <Link className="navbar-item has-text-weight-semibold" to="/news">
              News
            </Link>
            <Link
              className="navbar-item has-text-weight-semibold"
              to="/contact"
            >
              Contact
            </Link>
          </div>
        </div>
      </nav>
    </header>
  )
}

export default NavBar
